import React, { useEffect } from "react";
import Header from "../components/Header";
import Courses from "../components/Courses";
import About from "../components/About";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";

const HomePage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Courses />
      <About />
      <Testimonials />
      <Footer />
    </>
  );
};

export default HomePage;
