import { IoPersonCircle } from "react-icons/io5";
import img1 from "../images/testimonials/img1.jpg";
import img2 from "../images/testimonials/img2.jpg";
// import img3 from "../images/testimonials/img3.jpg";
import img4 from "../images/testimonials/img4.jpg";
import img5 from "../images/testimonials/img5.jpg";
import img6 from "../images/testimonials/img6.jpg";
import img7 from "../images/testimonials/img7.jpg";
import img8 from "../images/testimonials/img8.jpg";
import img9 from "../images/testimonials/img9.jpg";
import img10 from "../images/testimonials/img10.jpg";
// import img11 from "../images/testimonials/img11.jpg";
// import img12 from "../images/testimonials/img12.jpg";
import img13 from "../images/testimonials/img13.jpg";

const data = [
  {
    id: 1,
    name: "Priyanka Duvva",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img10,
    message:
      "The way he explains the concepts are good,like anyone can understand easily. I have found perfect teacher for my requirement. ",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 2,
    name: "Vikas Ghag",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img1,
    message:
      "Great till now and good experience. Best knowledge provided by Paras sir. Thank you so much for your support. Highly recommended to all.",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 3,
    name: "Sapna",
    // location: "London, UK",
    designation: "attended Stock Market Trading",
    avatar: <IoPersonCircle />,
    image: img13,
    message:
      "Amazing explanation on financial market. Explained approach of how to select prices And good stocks. He is best for beginners. He taught from where to start in stock market.",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 3,
    name: "Sagar Marick",
    // location: "London, UK",
    designation:
      "attended Stock Market Trading: Options Trading Strategies for Beginners",
    avatar: <IoPersonCircle />,
    image: img2,
    message:
      "I love it it was nice to meet you and. Have a class with you. Will like to have another master class with you and hope it will be soon.",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 4,
    name: "Shivam Kalra",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img4,
    message:
      "He is such a nice teacher with a deep knowledge of stock market and other financial markets too. I loved the course till now.",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 5,
    name: "Paras Jain",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img8,
    message:
      "I am lucky that I find you as my stock market mentor. Sir keep guiding me. I don't have words to appreciate your knowledge, communication skills and the way you teach. I would wanna join your next level course as soon as possible. But for that I have to complete my target you have given to me. Thank you sir.",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 6,
    name: "Karishma Devnani",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img5,
    message:
      "Paras is very professional and sticks to the course . Great learning and some one who guides you to learn for a long-term. Very humble and ready to answer all your questions with patience. You cannot find a better guide than him especially in the stock market . ",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 7,
    name: "Rajat Roy",
    // location: "London, UK",
    designation: "attended Stock Market Trading",
    avatar: <IoPersonCircle />,
    image: img6,
    message:
      "Paras is a versatile genius. He has excellent communication skills and a professional stock market trainer.",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 8,
    name: "Nitish Shukla",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img7,
    message:
      "This guy is amazing. Because of The tips that he gave, I made some real money. Thanks bro. Keep up the good work. ",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 9,
    name: "Afsheen Jahan",
    // location: "London, UK",
    designation: "attended OPTIONS TRADING BASICS",
    avatar: <IoPersonCircle />,
    image: img9,
    message:
      "An exhilarating experience for me as I found the insights really helpful & value additive. If you are venturing into the world of trading, Mr. Paras Arora is the right man to be your guide and mentor for the same! ",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
  {
    id: 10,
    name: "Madhav Manchanda",
    // location: "London, UK",
    designation: "attended Class 12 Tuition",
    avatar: <IoPersonCircle />,
    image: img8,
    message:
      "have learned so much in my classes with paras sir. He paces the class just right so you feel challenged but not overwhelmed. So many other classes you just read from a text book but in his classes asks questions and gets the students to respond which is both fun and promotes faster learning. He is patient and eager to help. I’m thrilled to have found his class!",
    lorem:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas non fringilla leo. Phasellus dui erat, ultrices sollicitudin ante quis, convallis varius odio. Quisque sed tristique ante.",
    rating: 4.8,
    audio: "https://testimonialapi.toolcarton.com/audio/1.mp3",
  },
];
export default data;
