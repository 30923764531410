import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const OnlineRegistration = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* Terms & Condirions */}
      <div className=" w-screen justify-between p-5">
        <div className="">
          <h1 className="text-6xl pl-5 font-semibold py-12 pb-20 mb-4 border-b border-white w-full tracking-wide">
            Online Registration
          </h1>
          <div className="flex gap-10">
            <div>
              <h2 className="text-xl font-semibold mb-1">Updated</h2>
              <h2 className="text-lg">March 23, 2024</h2>
            </div>
            <div>
              <h4 className="text-lg">
                These Terms & Conditions govern the use of the
                exponentialworld.in website operated by us. By accessing or
                using the Website, you agree to be bound by these Terms. If you
                disagree with any part of the terms, then you may not access the
                Website.
                <br></br>
                <strong>Intellectual Property</strong> <br></br> The Website and
                its original content, features, and functionality are owned by
                exponentialworld.in and are protected by international
                copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws. <br></br>{" "}
                <strong>Content</strong> <br></br> Our courses are for
                educational purposes only and do not constitute financial
                advice. We are not liable for any losses incurred from trading
                based on the information provided in our courses. <br></br>
                <strong>Purchases</strong> <br></br>If you wish to purchase any
                product or service made available through the Website, you may
                be asked to supply certain information relevant to your Purchase
                including, without limitation, your credit card number, the
                expiration date of your credit card, your billing address, and
                your shipping information. You represent and warrant that: (i)
                you have the legal right to use any credit card(s) or other
                payment method(s) in connection with any Purchase; and that (ii)
                the information you supply to us is true, correct, and complete.
                We reserve the right to refuse or cancel your order at any time
                for reasons including but not limited to: product or service
                availability, errors in the description or price of the product
                or service, error in your order, or other reasons. <br></br>{" "}
                <strong>Refund Policy</strong> <br></br>
                We offer a [number] day money-back guarantee for our courses. If
                you are not satisfied with your purchase, please contact us
                within [number] days of your purchase to arrange for a refund.{" "}
                <br></br> <strong>Links to Other Websites</strong> <br></br>Our
                Website may contain links to third-party websites or services
                that are not owned or controlled by [Your Company Name]. [Your
                Company Name] has no control over and assumes no responsibility
                for, the content, privacy policies, or practices of any
                third-party websites or services. You further acknowledge and
                agree that [Your Company Name] shall not be responsible or
                liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with the use of or
                reliance on any such content, goods, or services available on or
                through any such websites or services. Changes to These Terms We
                reserve the right, at our sole discretion, to modify or replace
                these Terms at any time. If a revision is material, we will try
                to provide at least 30 days' notice prior to any new terms
                taking effect. What constitutes a material change will be
                determined at our sole discretion. Contact Us If you have any
                questions about these Terms, please contact us at [Your Contact
                Information].
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OnlineRegistration;
