import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* Terms & Condirions */}
      <div className=" w-screen justify-between p-5">
        <div className="">
          <h1 className="text-6xl pl-5 font-semibold py-12 pb-20 mb-4 border-b border-white w-full tracking-wide">
            Privacy & Policy
          </h1>
          <div className="flex gap-10">
            <div>
              <h2 className="text-xl font-semibold mb-1">Updated</h2>
              <h2 className="text-lg">March 23, 2024</h2>
            </div>
            <div>
              <h4 className="text-lh">
                This Privacy Policy shall form a part of the user agreement
                between you and Exponential World. This document is an
                electronic record in terms of Information Technology Act, 2000
                and rules there under as applicable and the amended provisions
                pertaining to electronic records in various statutes as amended
                by the Information Technology Act, 2000. This electronic record
                is generated by a computer system and does not require any
                physical or digital signatures. This document is published in
                accordance with the provisions of Rule 3 (1) of the Information
                Technology (Intermediaries guidelines) Rules, 2011 that require
                publishing the privacy policy for access or usage of
                https://exponentialworld.in/. The domain name
                https://exponentialworld.in/ (“Website”), is owned and operated
                by Exponential World (“a sole proprietorship firm, headed by its
                proprietor Mr. Paras Arora”) having its registered office at
                Wing 3/92C, Opp mdda park main gate, Prem nagar, Dehradun,
                Uttrakhand-248007 where such expression shall unless repugnant
                to the context thereof, be deemed to include its respective
                representatives, administrators, employees, directors, officers,
                agents, and their successors and assigns. By accessing and/or
                using the website, you signify your agreement to accept this
                privacy policy. If you do not agree with any or all of the
                following terms of the privacy policy, please do not access
                and/or use the website. Providing information to us is your
                choice. You understand that any data, information, content or
                information that you submit to the website will be stored on the
                Booming Bulls’s servers for the purposes of making the website
                available to you. Please do not submit to the website any data,
                information, content or information which would include any
                personal information and sensitive personal data or information
                that you do not wish to be made available to the Exponential
                World. If you do not agree with any or all of the terms of this
                privacy policy, please do not provide any information to us. If
                at any time you wish to discontinue your access of the website,
                you are free to do so. Further, if you require you can choose to
                have the personal information provided by you deleted by writing
                to info@exponentialworld.in We reserve the right, at our sole
                discretion, to change or modify this privacy policy at any time
                without prior notice. Such changes and/or modifications shall
                become effective immediately upon being posted/published on the
                website herein. Please review the privacy policy from
                time-to-time. Your continued use of the website following the
                posting of changes and/or modifications will constitute your
                acceptance of any revised privacy policy. The Exponential World
                retains the right at any time to deny or suspend access to all
                or part of the website to anyone who the Exponential World
                believes has violated any condition of this privacy policy. If
                you are found reproducing our course content, or selling our
                strategy by recording or sharing, you must be liable for lawsuit
                and a compensation of 1.5 Lakh.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
