import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { IoCall } from "react-icons/io5";
import { IoLocation } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div className="contact-bg">
        <div className="grid items-center h-[50vh]">
          <div className="lg:ml-28 ml-5">
            <h2 className="text-5xl font-bold mb-2">Contact</h2>
            <h2>We’re always open to talk to good people</h2>
          </div>
        </div>
      </div>
      <div className="lg:flex justify-evenly grid gap-7 items-start py-28">
        <div className="flex items-center gap-5">
          <div className="bg-purple-600 p-4 rounded-full">
            <IoCall className="w-6 h-6" />
          </div>
          <a href="tel:+917017538193">
            <h2 className="text-xl font-bold">Call Us</h2>
            <h2 className="text-lg text-gray-400">+91 (701) 753-8193</h2>
          </a>
        </div>
        <div className="flex items-start gap-5">
          <div className="bg-purple-600 p-4 rounded-full">
            <IoLocation className="w-6 h-6" />
          </div>
          <div>
            <h2 className="text-xl font-bold">Address</h2>
            <h2 className="text-lg font-normal text-gray-400">
              Wing 3/92C, Opp mdda park main gate, <br></br>
              Prem nagar, Dehradun, <br></br>
              Uttrakhand-248007
            </h2>
          </div>
        </div>
        <div className="flex items-center gap-5">
          <div className="bg-purple-600 p-4 rounded-full">
            <MdEmail className="w-6 h-6" />
          </div>
          <div>
            <h2 className="text-xl font-bold">Email</h2>
            <h2 className="text-lg text-gray-400">info@exponentialworld.in</h2>
          </div>
        </div>
      </div>
      <div>
        <iframe
          title="PremNagar"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13774.583289356906!2d77.95033825354719!3d30.332590596472418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092a9ce8a03a1f%3A0x2e31206d44d9f341!2sPrem%20Nagar%2C%20Dehradun%2C%20Uttarakhand%20248007!5e0!3m2!1sen!2sin!4v1711193795433!5m2!1sen!2sin"
          className="w-full h-[70vh] border-none"
          loading="lazy"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
