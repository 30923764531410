import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TermsConditions = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* Terms & Condirions */}
      <div className=" w-screen justify-between p-5">
        <div className="">
          <h1 className="text-6xl pl-5 font-semibold py-12 pb-20 mb-4 border-b border-white w-full tracking-wide">
            Terms & Conditions
          </h1>
          <div className="flex gap-10">
            <div>
              <h2 className="text-xl font-semibold mb-1">Updated</h2>
              <h2 className="text-lg">March 23, 2024</h2>
            </div>
            <div>
              {/* <h4 className="text-lg">
                These Terms & Conditions govern the use of the
                exponentialworld.in website operated by us. By accessing or
                using the Website, you agree to be bound by these Terms. If you
                disagree with any part of the terms, then you may not access the
                Website.
                <br></br>
                <strong>Intellectual Property</strong> <br></br> The Website and
                its original content, features, and functionality are owned by
                exponentialworld.in and are protected by international
                copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws. <br></br>{" "}
                <strong>Content</strong> <br></br> Our courses are for
                educational purposes only and do not constitute financial
                advice. We are not liable for any losses incurred from trading
                based on the information provided in our courses. <br></br>
                <strong>Purchases</strong> <br></br>If you wish to purchase any
                product or service made available through the Website, you may
                be asked to supply certain information relevant to your Purchase
                including, without limitation, your credit card number, the
                expiration date of your credit card, your billing address, and
                your shipping information. You represent and warrant that: (i)
                you have the legal right to use any credit card(s) or other
                payment method(s) in connection with any Purchase; and that (ii)
                the information you supply to us is true, correct, and complete.
                We reserve the right to refuse or cancel your order at any time
                for reasons including but not limited to: product or service
                availability, errors in the description or price of the product
                or service, error in your order, or other reasons. <br></br>{" "}
                <strong>Refund Policy</strong> <br></br>
                We offer a [number] day money-back guarantee for our courses. If
                you are not satisfied with your purchase, please contact us
                within [number] days of your purchase to arrange for a refund.{" "}
                <br></br> <strong>Links to Other Websites</strong> <br></br>Our
                Website may contain links to third-party websites or services
                that are not owned or controlled by [Your Company Name]. [Your
                Company Name] has no control over and assumes no responsibility
                for, the content, privacy policies, or practices of any
                third-party websites or services. You further acknowledge and
                agree that [Your Company Name] shall not be responsible or
                liable, directly or indirectly, for any damage or loss caused or
                alleged to be caused by or in connection with the use of or
                reliance on any such content, goods, or services available on or
                through any such websites or services. Changes to These Terms We
                reserve the right, at our sole discretion, to modify or replace
                these Terms at any time. If a revision is material, we will try
                to provide at least 30 days' notice prior to any new terms
                taking effect. What constitutes a material change will be
                determined at our sole discretion. Contact Us If you have any
                questions about these Terms, please contact us at [Your Contact
                Information].
              </h4> */}
              <h4>
                Please read these terms and conditions of use (these “Terms of
                Use”, “Disclaimer”, “Privacy Policy” & “Refund Policies”)
                carefully. By accessing or using the
                https://exponentialworld.in/ domain, any subdomain of this
                website (the “Website”), Creative Invites, Creative Studios and
                other services hosted by or on behalf of Exponential World or
                its affiliated companies (including without limitation the
                Website, collectively, the “Services”), you agree that you have
                read, understand and agree to comply with and be bound by these
                Terms and Conditions, whether or not you are a registered member
                of the Website or other Services. Please also read our Privacy
                Policy, Refund Policy and Disclaimer carefully to understand
                what we do with the personal information that we collect as part
                of your interaction with the Services. These Terms and
                Conditions, including without limitation the Privacy Policy,
                Refund Policy and Disclaimer, govern your use of the Services
                and constitute a legal contract between you and Exponential
                World (“Exponential World” or,” “we” or “us”). If you do not
                agree to abide by these terms and conditions, immediately
                discontinue your access to and use of the services. Any breach
                of these terms and conditions immediately terminates your right
                to access and use the services and all rights and licenses
                granted to you by these terms and conditions. In the future, we
                may change these Terms and Conditions. If we decide to change
                these Terms and Conditions, all changes will be made at this URL
                and the effective date will be posted so that you will always
                know what terms of use apply to your visit. In case of any
                objection, you may stop using the services. Of course, you can
                always choose to terminate your use of the Services by notifying
                us of your desire to do so at info@exponentialworld.in To the
                extent you have any questions, or complaints concerning these
                Terms and Conditions, please contact us at
                info@exponentialworld.in Content and Intellectual Property. The
                content provided through the Services by Exponential World and
                its licensors or suppliers, including but not limited to any
                text, illustrations, files, images, scripts, graphics, music,
                sounds, photographs, videos, information content, URLs,
                interactive features, documentation, or other materials
                (collectively, the “Content”) and all underlying software or
                technology used in connection with the Services (“Technology”),
                including but not limited to all intellectual property rights in
                and to the Content and Technology, is and remains at all times
                the property of Booming Bull or its licensors or suppliers, as
                the case may be, with all rights not explicitly granted herein
                reserved to Booming Bull, or its licensors or suppliers, as
                applicable. Nothing in these Terms and Conditions shall be
                interpreted as granting any license of intellectual property
                rights to you other than as explicitly set forth in these Terms
                and Conditions. If you would like to use any of our Content, you
                must first obtain written permission from Booming Bull by
                contacting us at info@exponentialworld.in. Exponential World
                reserves the right to refuse permission for any reason or no
                reason. Any use of the Content other than as expressly
                authorized herein, without the prior written permission of
                Exponential World, is strictly prohibited and shall immediately
                terminate your right to access and use the Services and all
                rights and licenses granted to you by these Terms and
                Conditions. Such unauthorized use may also violate applicable
                laws including without limitation copyright and trademark laws
                and applicable communications regulations and statutes. The
                trademarks, service marks, trade names and logos, including
                without limitation Exponential World™ and any third party marks
                used and displayed through the Services are trademarks of
                Exponential World , its licensors, affiliated companies or
                sponsors. The animations, music, page headers, custom graphics,
                button icons, style sheets and scripts that contribute to the
                “look and feel” of the Services are service marks, trademarks or
                trade dress of Exponential World and may not be copied, imitated
                or used, in whole or in part, in connection with any product or
                service that is not expressly authorized by Exponential World in
                writing, in any manner that is likely to cause confusion among
                consumers, or in any manner that disparages or discredits
                Exponential World , its affiliated companies or its or their
                sponsors, licensors, partners, customers or suppliers.
                Exponential World complies with all applicable Indian
                intellectual property laws. If you believe that your work has
                been copied in a way that constitutes intellectual property
                infringement, or your intellectual property rights have
                otherwise been violated, please provide the following
                information to the Exponential World : A description of the
                copyrighted work or other intellectual property that you claim
                has been infringed; A description of where the material that you
                claim is infringing is located on the Website or other Services;
                Your address, telephone number and email address; A statement by
                you that you have a good faith belief that the disputed use is
                not authorized by the copyright owner, its agent or the law; An
                electronic or physical signature of the person authorized to act
                on behalf of the owner of the copyright or other intellectual
                property interest, as applicable; and A statement by you, made
                under penalty of perjury, that the above information in your
                notice is accurate and that you are the copyright or
                intellectual property owner or authorized to act on such owner’s
                behalf. The Exponential World Agent can be reached as follows:
                By email: info@exponentialworld.in Exponential World will
                investigate the matter and, after such investigation and in its
                sole discretion, Exponential World will promptly remove any
                infringing content from the Website. Similarly, if you believe
                that any content on the Website or Services violates any
                proprietary or other rights of yours (other than your
                copyrights), please contact us using the above contact
                information and describe your concerns or complaints.
                Restrictions; Use of Services. The following restrictions apply
                to your use of the Services: (i) you will not engage in any
                activity related to the Services that is contrary to applicable
                law, regulation or the terms of any agreements you have with
                Exponential World or any other parties; (ii) you will not
                commercially exploit any part of the Services, except as
                expressly permitted by Exponential World; and (iii) all
                information and materials contained in the Services, except
                where otherwise expressly indicated, are owned and controlled by
                Exponential World . You may not use, modify, copy, distribute,
                frame, reproduce, republish, download, scrape, publicly display,
                publicly perform, post, transmit, sell or otherwise exploit any
                intellectual property or Content appearing on or through the
                Services, or create derivative works based on such Content, in
                any form or by any means, in whole or in part, without
                Exponential World’ prior written permission, unless expressly
                permitted elsewhere under these Terms and Conditions. You may
                not circumvent, disable or otherwise interfere with
                security-related features of the Services or features that
                prevent or restrict use or copying of any Content or enforce
                limitations on use of the Services or the Content. Any
                unauthorized use of the Services, including but not limited to
                misuse or any other use in violation of these Terms and
                Conditions of any information or Content, is strictly
                prohibited. If you violate any of your obligations or
                restrictions set forth in these Terms and Conditions, your
                permission to use and access the Services and the Content
                automatically terminates and you must immediately destroy any
                copies you have made of any portion of the Content. You have to
                create an account in order to use the Services. You are
                obligated not to gain unauthorized access to the Services, or
                any account, computer system or network connected to the
                Services, for example, through hacking, password mining or any
                other illicit or unauthorized means. When accessing or using the
                Services, you are strictly prohibited from and you are hereby
                obligated NOT to: Write any unsolicited communications or social
                media reviews or defamatory statements or advertising not
                expressly authorized by Exponential World in writing, including
                without limitation promotional materials, affiliate marketing,
                junk mail, spam, link referral code, chain letters, or pyramid
                schemes, or post links to external websites, unless integral to
                the conversation, as determined by Exponential World in
                Exponential World ’ sole discretion; try to upload any material
                that contains software viruses, worms, or any other computer
                code, files or programs designed to interrupt, destroy or limit
                the functionality of any computer software or hardware or
                telecommunications equipment; use the Services to impersonate
                any person or entity, including but not limited to an employee
                of Exponential World or its affiliated companies, or falsely
                state or otherwise misrepresent yourself, your age or your
                affiliation with a person or entity; instigate or encourage
                others to commit illegal activities or cause injury or property
                damage to any person; intentionally or unintentionally violate
                any applicable local, state, national or international law;
                violate the terms of any third party website or service, or the
                terms of any other agreement with any third party; Suggestions
                and Feedback. Please note that we do accept or consider ideas,
                suggestions or materials. If you send us suggestions, ideas,
                notes, drawings, concepts or feedback related to the Services,
                all such submissions shall be and are hereby deemed to be
                confidential, and Exponential World shall not be and hereby is
                not liable for any use or disclosure of any such submissions.
                Without limitation of the foregoing, Exponential World shall be
                entitled to unrestricted use of any suggestions, ideas, notes,
                drawings, concepts or feedback related to the Services for any
                purpose whatsoever, commercial or otherwise, without
                compensation to you or to any other person or entity.
                Third-Party Sites. These Terms and Conditions only relate to
                your use of the Services and do not relate to any other website
                or Internet-based services, including websites to which the
                Services may link (“Third-Party Sites”). We are not responsible
                or liable for any content, advertising, products, services,
                information or other materials on or available on Third-Party
                Sites. We also are not responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with the use of or reliance on any
                content, advertising, products, services, information or other
                materials on Third-Party Sites. We have no control over
                Third-Party Sites, their content or their terms of use, and
                therefore we encourage you to review such content and terms of
                use prior to utilizing any Third-Party Sites or submitting any
                personal information to them. Exponential World may provide
                experiences on social media platforms such as Pinterest®,
                Facebook® or Twitter® that enable online sharing and
                collaboration among users who have registered to use them. User
                agrees not to post any such defamatory statements on such social
                media platforms. Any content you post is subject to the terms of
                use and privacy policies of those platforms and related
                services. Exponential World has no control over such social
                media platforms or related services. Termination. The payment
                contract automatically ends when the User has completed the
                access of the webinar he has paid for and the Exponential World
                reserves the right, in its sole discretion, to refuse, suspend,
                restrict or terminate your access to the Services, or any
                portion thereof, without notice and for any reason or no reason.
                Furthermore, Exponential World has the right, but not the
                obligation, to suspend or terminate your access to all or part
                of the Services: At the request of law enforcement or other
                government agencies; If the Services are discontinued or
                materially modified; Upon the occurrence of any technical or
                security issues or problems; or If you engage in any conduct
                that Exponential World believes, in its sole discretion,
                violates any provision of these Terms and Conditions or other
                incorporated agreements or guidelines or violates the rights of
                Exponential World or third parties. If we notice that you are
                directly or indirectly involved in or running the same business
                as we have (such as training institute, selling of stock
                market-related courses) then we have the right to terminate the
                services we offer without any notice. In addition, Exponential
                World may terminate individual user accounts due to inactivity.
                You agree that upon termination Exponential World may delete all
                information related to you. You agree that none of the Released
                Entities will be liable to you or any third party for any
                termination or suspension of your account or for blocking your
                access to the Services. Miscellaneous. If any provision of these
                Terms and Conditions is found to be invalid, unlawful, void or
                for any reason unenforceable, then that provision shall be
                deemed severable from these Terms and Conditions and shall not
                affect the validity and enforceability of any remaining
                provisions. These Terms and Conditions, which includes the
                Privacy Policy and Disclaimer, represent the entire agreement
                between you and Exponential World relating to your right to
                access and use the Services, and supersedes any and all prior or
                written or oral agreements between you and Exponential World
                with respect to such subject matter. No waiver by Exponential
                World of any breach or default by you under these Terms and
                Conditions shall be deemed to be a waiver of any preceding or
                subsequent breach or default. Any waiver by Exponential World
                must be in writing signed by Exponential World . We refund 100%
                if you’re not satisfied after first 3(weekday) or 2 (weekend)
                classes. There will be no refund issued after the complete full
                payment of the course fees. Regardless of any statute or law to
                the contrary, any claim or cause of action arising out of or
                related to use of the Services or these Terms and Conditions
                must be filed within six months after such claim or cause of
                action arose or will be forever barred. No provision of these
                Terms and Conditions provides any person or entity not a party
                to these Terms and Conditions with any remedy, claim, liability,
                reimbursement or cause of action, or creates any other third
                party beneficiary rights, except as expressly set forth in these
                Terms and Conditions. DISCLAIMER The services, including the
                website and the content, are provided “as is” and without
                warranties of any kind, either express or implied. To the
                fullest extent permissible pursuant to applicable law,
                Exponential World and its affiliated companies, licensors,
                suppliers, partners and sponsors, and each of their respective
                directors, officers, agents, contractors, advisors, employees,
                licensors and suppliers (“released entities”) disclaim all
                warranties, express or implied, including, without limitation,
                implied warranties of title, non-infringement, accuracy,
                merchantability and fitness for a particular purpose, and any
                warranties that may arise from a course of dealing, course of
                performance or usage of trade. For the sake of clarity,
                Exponential World does not warrant or covenant: That the
                services or content will meet your requirements or be available
                at any time or from any particular location; That the services
                or content will be accurate, secure, useful, timely,
                informational, uninterrupted or error-free; That defects or
                errors will be corrected; or That the services are free of
                viruses or other potentially harmful components. All or a
                portion of the Services may be temporarily unavailable from time
                to time for maintenance or other reasons. Exponential World
                assumes no responsibility for any error, omission, interruption,
                deletion, defect, delay in operation or transmission,
                communications line failure, theft or destruction or
                unauthorized access to, or alteration of, any communications.
                Exponential World also is not responsible for any technical
                malfunction or other problems of any telephone network or
                service, computer systems, servers or providers, computer or
                mobile phone equipment, software, failure of email on account of
                technical problems or traffic congestion on the Internet or at
                any website or combination thereof, including without limitation
                injury or damage to any person’s computer (including without
                limitation any tablet or smartphone) or other hardware or
                software, related to or resulting from using or downloading
                materials in connection with the Internet or in connection with
                the Services. Under no circumstances will Exponential World be
                responsible for any loss, damage, personal injury or death
                resulting from anyone’s use of the Services. Exponential World
                has no special relationship with or fiduciary duty to you. For
                the avoidance of doubt, nothing in these Terms and Conditions
                shall seek to limit or exclude our liability for death or
                personal injury caused by negligence, for fraud or for
                fraudulent misrepresentation. Warranty disclaimer; limitation of
                liability. To the fullest extent permitted by applicable law,
                Exponential World makes no representations or warranties
                whatsoever, express or implied, with respect to the services,
                including without limitation any (i) warranty of
                merchantability; (ii) warranty of fitness for a particular
                purpose; or (c) warranty against infringement of intellectual
                property rights of a third party, whether express or implied by
                law, course of dealing, course of performance, usage of trade or
                otherwise. In no event shall any released entity be liable to
                you or to any third party for any loss of use, revenue or profit
                or loss of data or diminution in value, or for any
                consequential, incidental, indirect, exemplary, special or
                punitive damages whether arising out of breach of contract, tort
                (including negligence) or otherwise, regardless of whether such
                damage was foreseeable and whether or not such party has been
                advised of the possibility of such damages, and notwithstanding
                the failure of any agreed or other remedy of its essential
                purpose. Our liability in the case of gross negligence is
                limited to foreseeable damages typical for this type of
                contract. The total liability of Exponential World towards You
                in respect of all other losses arising under or in connection
                with these terms and conditions, tort (including negligence),
                breach of statutory duty, or otherwise, will not exceed an
                amount equal to the course fees payable by the User under the
                agreement in relation to the Services giving rise to the
                liability. If the whole or any part of any provision of these
                terms of use, privacy policy, disclaimer or refund policy is or
                becomes invalid, void or unenforceable for any reason the same
                shall to the extent required be severed from these terms of use
                and rendered ineffective so far as is possible without modifying
                the remaining provisions of these terms of use and shall in no
                way affect the validity or enforceability of any other
                provisions. Release To the maximum extent permitted by
                applicable law, you hereby release and waive all claims against
                the Exponential World from any and all liability for claims,
                demands, damages (actual and consequential), costs and expenses
                (including litigation costs and attorneys’ fees) of every kind
                and nature, arising out of or in any way connected with the use
                of the Services, including without limitation any dispute
                between users of the Services or between a user of the Services
                and any third party. To the extent that you do have any claims
                against Exponential World , you agree that: Any and all
                disputes, claims and causes of action against Booming Bull
                arising out of or connected with your use of the Services shall
                be resolved individually, without resort to any form of action;
                and Any and all claims, judgments and awards shall be limited to
                actual damages and out-of-pocket costs incurred, but shall in no
                event include attorneys’ fees. Indemnification. You agree to
                indemnify and hold the Exponential World and their successors
                and assigns harmless from and against any loss, liability,
                claim, demand, damages, costs and expenses, including without
                limitation reasonable attorneys’ fees, arising out of or in
                connection with: Your use of the Services other than in
                accordance with these Terms and Conditions; Your conduct, acts
                and omissions in connection with the Services which breach these
                Terms and Conditions; Any dispute related to the Services
                between you and one or more users of the Services; and Your
                violation of these Terms and Conditions, any agreements between
                you and any third party relating to your use of the Services, or
                of any law or regulation or the rights of any third party,
                including but not limited to intellectual property rights. The
                foregoing indemnification obligation will survive these Terms
                and Conditions and your use of the Services. Exponential World
                shall provide notice to you of any such claim, action or demand
                and Exponential World may, in Exponential World ’ sole
                discretion, assist you, at your reasonable expense, in the
                defense or settlement of such claim, action or demand. You agree
                to cooperate fully with us in the defense of any claim that is
                the subject of your obligations hereunder. Disputes; Governing
                Law; Choice of Forum Any dispute, controversy or claims arising
                out of or relating to this Agreement or the breach, termination
                or invalidity thereof, shall be settled by arbitration in
                accordance with the provisions of the [Indian] Arbitration and
                Conciliation Act, 1996 as amended up to date. If the parties
                cannot agree upon an arbitrator within thirty (30) days from the
                date the claimant’s request for arbitration was served on the
                other party or parties, the sole arbitrator shall be appointed
                by the Law in accordance with the Rules. The cost of arbitration
                proceedings including the fee of the arbitrator shall be borne
                by both the parties in equal proportions. The place of
                arbitration shall be at New Delhi and any award whether interim
                or final, shall be made, and shall be deemed for all purposes
                between the Parties to be made in New Delhi. The arbitral
                procedure shall be conducted in the English language and any
                award or awards shall be rendered in English. The procedural law
                of the arbitration shall be Indian law. The award of the
                arbitral tribunal shall be final, conclusive and binding upon
                the Parties, and the provisions of the [Indian] Arbitration and
                Conciliation Act, 1996 as amended up to date, shall apply. The
                arbitrator will have the authority to award money damages (with
                interest on unpaid amounts from the date due), specific
                performance, and temporary injunctive relief, but not the
                authority to award exemplary or punitive damages, and any
                claimed right to such damages is expressly waived. The rights
                and obligations of the Parties under, or pursuant to, this
                Clause, including the arbitration Agreement in this Clause,
                shall be governed by and be subject to Indian law, and the
                Agreement shall be subject to the exclusive jurisdiction of the
                courts at New Delhi, INDIA. Your Rights You have the right to
                access personal information we hold about you and to ask that
                your personal information be corrected, updated, or deleted. If
                you would like to exercise this right, please contact us through
                the contact information below. How to contact us If you have any
                questions about our privacy policy or personal information we
                hold about you, please contact us by email at the following
                address: info@exponentialworld.in Personal identification
                information We may collect personal identification information
                from Users in a variety of ways, including, but not limited to,
                when users visit our site, fill out a form (opt-in forms) and in
                connection with other activities, services, features or
                resources we make available on our Site. Users may be asked for,
                as appropriate, name, email address, phone number and IP
                address. Also, Users need to provide Aadhar Card & PAN Card for
                Verification Purpose, This data will not be shared outside, only
                collecting for Internal Records. Users may, however, visit our
                site anonymously. We will collect personal identification
                information from users only if they voluntarily submit such
                information to us. All the documents we collect from you is for
                our internal verification purpose only. We do not share any data
                with any third party outside our organisation and also with
                anyone personally within our organization. While making payments
                online for the course, Exponential World may save your credit
                card or debit card details. Exponential World assures and agrees
                not to share such data with any third party. The price payable
                for the webinar or course shall be the list price of Exponential
                World at the Confirmation Date unless otherwise stated. That
                Booming Bulls reserves the right to increase the price from that
                advertised on the ’s website or in other forms at their
                discretion and for any reason prior to the course commencement.
                If the User has made payment of the previously advertised price
                then the same shall be informed about such an increase in the
                prices and the User will be at liberty to choose whether or not
                the User wishes to continue with the same program and Booming
                Bulls shall be at its complete discretion with regard to refunds
                in such scenario. All prices are exclusive/inclusive of
                applicableTaxes, and this will be charged at the appropriate
                rate. Exponential World does not provide any installment payment
                of the webinar or online course to the User. All the payments
                are to be paid at once. Users can always choose not to supply
                personal identification information, except that it may prevent
                them from engaging in certain site-related activities. The
                Exponential World is committed to ensuring the security and
                privacy of your personal information. We may now collect
                additional data, such as UID details, for KYC (Know Your
                Customer) purposes to enhance the security and authenticity of
                our services. However, we want to reassure you that your
                sensitive personal data is handled with the utmost care and will
                not be misused. The Exponential World, including its directors,
                key managerial personnel, officers, and employees, will never
                ask you for information beyond what is necessary for KYC. You
                must only provide such information through secure channels
                provided on our website. You must not disclose your: Passwords;
                Physical, physiological and mental health condition; Sexual
                orientation; Medical records and history; And such other
                sensitive personal data or information. Users must note that any
                information that is freely available or accessible in public
                domain shall not be regarded as personal information or
                sensitive personal data or information for the purposes of this
                privacy policy and the Exponential World shall not be obliged to
                take any measures to protect the same since the same is freely
                available in the public domain. Please note that identity theft
                and the practice currently known as “phishing” are of great
                concern to the Exponential World. We do not and will not, at any
                time, request your credit card information/debit card
                information/financial pin numbers and passwords. You hereby
                consent to the collection, storage, use, disclosure, transfer,
                processing of the personal information for the purposes set out
                herein. We may disclose your personal information in the
                circumstances set out below and you hereby consent to the same
                to be shared with: Any third-party service provider to whom
                disclosure is necessary to enable us to provide you with the
                services which you wish to access on or through the website; Any
                person/entity to whom disclosure is necessary in accordance with
                applicable law; Any government or statutory authority or court
                of law or judicial forum to whom disclosure is necessary in
                accordance with applicable law; In circumstances we believe
                necessary or appropriate to respond to valid claims and legal
                process, to protect the property and rights of the Exponential
                World, to protect the safety of the public or any person or
                user, or to prevent or stop any illegal, unethical or legally
                actionable activity; Any person/entity to whom disclosure is
                necessary to enable us to enforce our rights; To our associate
                companies, business partners, agents or third parties for the
                purposes of the services or for any other marketing and
                promotional activity undertaken by or on behalf of the
                Exponential World; Any entity that legally acquires the
                Exponential World or its website. We understand the importance
                of data security and we want your browsing experience with us to
                be as safe as possible. We have implemented reasonable
                safeguards and precautions to protect your personal information.
                We do not sell, trade, or rent users personal identification
                information to others. We may share generic aggregated
                demographic information not linked to any personal
                identification information regarding visitors and users with our
                business partners, trusted affiliates and advertisers for the
                purposes outlined above. Please understand that, while we strive
                to protect your personal data against potential risks and
                exposures, there is no absolute security in the online/ internet
                sphere. Therefore, you must not disclose any information on the
                website that is sensitive personal data or information. You
                understand that the transmission of information over the
                internet is not completely secure and there are risks associated
                with it. Although we strive to protect your personal
                information, we cannot guarantee the security of the same while
                it is being transmitted to our website and any transmission is
                at your own risk. Transactions on the Website are secure and
                protected. Any information entered by the User when transacting
                on the Website is encrypted to protect the User against
                unintentional disclosure to third parties. The User’s credit and
                debit card information is not received, stored by or retained by
                the Exponential World / Website in any manner. This information
                is supplied by the User directly to the relevant payment gateway
                which is authorized to handle the information provided and is
                compliant with the regulations and requirements of various banks
                and institutions and payment franchisees that it is associated
                with. Refund Policy Exponential World reserves the right to
                cancel or arrange an alternative date for the webinar conducted
                online or online course. We refund 100% if you’re not satisfied
                after first 3(weekday) or 2 (weekend) classes. Cookies By
                default, your web browser will accept cookies, however, this can
                be altered by you. You have the option of enabling or disabling
                cookies in your web browser. If you do not want us to install
                cookies, you may change the settings on your web browser to
                disable cookies. However please note that if you disable
                cookies, you may not be able to use all of the features of the
                website. The website and/or third parties may use “cookies”, and
                other similar tracking technologies (collectively, “tracking
                technologies”) to collect information automatically as you
                browse the website and the internet. For the purposes of this
                clause below terms have the meanings as set out below: By
                visiting the website whether as a registered user or otherwise,
                you acknowledge, understand and hereby agree that you are giving
                us your consent to track your activities and your use of the
                website through these tracking technologies. If you do not
                consent to any part of the privacy terms herein or wish to
                withdraw consent to process your information, then you may stop
                using the website and ask us to remove your information from our
                records by writing to us at info@exponentialworld.in Dispute
                Resolution and Jurisdiction In the event that the Parties are
                unable to amicably resolve a dispute by mediation, said dispute
                will be referred to arbitration by a sole arbitrator to be
                appointed by the Exponential World, and the award passed by such
                sole arbitrator will be valid and binding on both Parties. The
                Parties shall bear their own costs for the proceedings, although
                the sole arbitrator may, in his/her sole discretion, direct
                either Party to bear the entire cost of the proceedings. The
                arbitration shall be conducted in English, and the seat of
                Arbitration shall be in New Delhi, India. The Parties expressly
                agree that the Terms, Policy and any other agreements entered
                into between the Parties are governed by the laws, rules, and
                regulations of India, and that the Courts at New Delhi, India,
                shall have exclusive jurisdiction over any disputes arising
                between the Parties. GRIEVANCE OFFICER In accordance with
                Information Technology Act 2000 and rules made thereunder, the
                name and contact details of the Grievance Officer are provided
                below: Anish Singh Thakur (E-mail: info@exponentialworld.in)
                Thank you for using our Services. We hope you find the Services
                helpful, informative and convenient to use. If you have any
                questions regarding these Terms and Conditions, please contact
                us by email at info@exponentialworld.in
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
