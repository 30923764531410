import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const RefundsCancellation = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      {/* Terms & Condirions */}
      <div className=" w-screen justify-between p-5">
        <div className="">
          <h1 className="text-6xl pl-5 font-semibold py-12 pb-20 mb-4 border-b border-white w-full tracking-wide">
            Cancellation & Refund Policy
          </h1>
          <div className="flex gap-10">
            <div>
              <h2 className="text-xl font-semibold mb-1">Updated</h2>
              <h2 className="text-lg">March 23, 2024</h2>
            </div>
            <div>
              <h3>
                The terms hereof shall constitute Exponential World (“Company”)
                cancellation and refund policy in relation to the Solutions
                rendered on the Website (“Cancellation and Refund Policy”).
                Capitalized terms not defined herein shall have the meaning
                ascribed to them in the Terms of Use. <br></br> <br></br>
                <p className="font-semibold">
                  Cancellation and Refunds by the Company
                </p>
                <br></br>
                Please note that there may be certain orders that we are unable
                to accept and must cancel. We reserve the right, at our sole
                discretion, to refuse or cancel any order for any reason,
                without any claims or liability to pay finance charges or
                interest on the amount. Some situations that may result in your
                order being canceled include but are not limited to inaccuracies
                or errors in Solutions or pricing information, technical or
                technological problems or problems identified in relation to
                credit / debit fraud. We may also require additional
                verifications or information before accepting any order. We will
                contact you if all or any portion of your order is canceled or
                if additional information is required to accept your order. If
                your order is cancelled by the Company after your credit / debit
                card has been charged, the said amount will be refunded to that
                credit / debit card account. <br></br> <br></br>
                <p className="font-semibold">Cancellation by you</p> <br></br>
                You agree and acknowledge that unless stated otherwise you are
                not entitled to cancel any orders made by you on this Website.
                In the event you subscribe to any Solutions, the same may be
                cancelled by you one month prior to the provision of the
                Solutions, in such a case you will be refunded in the entire
                amount after deducting any bank charges that may have been
                applicable. Further during a period between one month and 14
                days from when the Solutions are to be provided, if there is a
                cancellation request received, we may at our discretion refund
                50% of the amount, after deducting any bank charges that may
                have been applicable, to you. A period of 14 days before the
                Solutions to be provided no request for cancelation will not be
                entertained by the Company.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundsCancellation;
