// import { IoPersonCircle } from "react-icons/io5";

function Card(props) {
  return (
    <div className="main">
      <div className="Client-Card" style={{ display: "flex" }}>
        {/* <IoPersonCircle
          // imgProps={{ style: { borderRadius: "50%" } }}
          className=""
          size={123}
          src={props.avatar}
        /> */}
        <img
          src={props.avatar}
          alt="Testimonial"
          style={{ borderRadius: "50%", width: "123px", height: "123px" }}
        />
        <p style={{ marginTop: 25 }}>
          <span>&ldquo;</span>
          {props.message}
        </p>
        <div className="fixed bottom-2 w-100 bg-opacity-20 px-5 bg-white border-0 rounded-md">
          <p>
            <span className="Name">
              {props.name} , {props.location}
            </span>
          </p>
          <p className="designation">
            <span>{props.designation}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Card;
