import React from "react";
import "./App.css";
import HomePage from "./pages/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from "./pages/ContactUs.js";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundsCancellation from "./pages/RefundsCancellation";
import OnlineRegistration from "./pages/OnlineRegistration";

function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/refunds-and-cancellation"
            element={<RefundsCancellation />}
          />
          <Route path="/online-registration" element={<OnlineRegistration />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
